// https://www.joshwcomeau.com/react/the-perils-of-rehydration/
import React, { FC, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  delegated: { [x: string]: any };
}

const ClientOnly: FC<Props> = ({ children, ...delegated }) => {
  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
  }, []);
  if (!hasMounted) {
    return null;
  }
  return <div {...delegated}>{children}</div>;
};

export default ClientOnly;
